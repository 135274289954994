// stylelint-disable

// # Crimson Text

@font-face {
    font-family: 'Avenir Heavy';
    src: url('./fonts/Avenir-Heavy.woff2') format('woff2'),
        url('./fonts/Avenir-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Light';
    src: url('./fonts/Avenir-Light.woff2') format('woff2'),
        url('./fonts/Avenir-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Medium';
    src: url('./fonts/Avenir-Medium.woff2') format('woff2'),
        url('./fonts/Avenir-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Medium Oblique';
    src: url('./fonts/Avenir-MediumOblique.woff2') format('woff2'),
        url('./fonts/Avenir-MediumOblique.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

//New Fonts
$font-main: 'Avenir Light', sans-serif;
$font-alt: 'arno-pro', sans-serif;
$font-alt1: 'Avenir Heavy', sans-serif;
$font-alt2: 'Avenir Medium', sans-serif;
$font-alt3: 'arno-pro-display', sans-serif;
