// # Applied colors palette #
//============================================
// ## Main colors ##
$color-primary: #404442;
$color-primary-background: #ffffff;
$color-select-option: #0c0c0c;
$color-error: #CA1E36;
$color-error-background: #faeded;
$color-success: #37a10a;
$color-promo-background: #a32921;
$color-notification-background: #bfb9a1;
$color-input-border: #a0a0a0;
$color-accent: #a32921;
$color-text: #000000;
// ## Shades ##
$color-shade_1: #000000;
$color-shade_2: #D4D6D5;
$color-shade_3: #d8d8d8;
$color-shade_4: #707070;
$color-shade_5: #636466;
$color-shade_6: #000000;
$color-shade_7: #000000;
$color-shade_8: #F0F2F1;
$color-shade_9: #75787B;
$color-shade_10:#fafafa;
$color-shade_11: #770A24;
// ## GH/GF colors ##
$color-header_text: $color-shade_6;
$color-header_alt: $color-primary-background;
$color-footer_text: $color-primary;
$color-footer_alt: $color-primary;

// ## Buttons ##
// ### Button Main ###
$color-button_main-background: $color-shade_11;
$color-button_main-border: $color-shade_11;
$color-button_main-text: $color-primary-background;
$color-button_main__hover-background: $color-primary-background;
$color-button_main__hover-border: $color-shade_1;
$color-button_main__hover-text: $color-shade_7;
$color-button_main__inversed-background: transparent;
$color-button_main__inversed-border: #a32921;
$color-button_main__inversed-text: $color-primary-background;
$color-button_main__inversed__hover-background: #a32921;
$color-button_main__inversed__hover-border: #a32921;
$color-button_main__inversed__hover-text: $color-primary-background;
// ### Alt ###
$color-button_alt-background: $color-primary-background;
$color-button_alt-border: $color-shade_1;
$color-button_alt-text: $color-shade_7;
$color-button_alt__hover-background: $color-shade_1;
$color-button_alt__hover-border: $color-shade_1;
$color-button_alt__hover-text: $color-shade_7;
$color-button_alt__inversed-background: transparent;
$color-button_alt__inversed-border: $color-primary-background;
$color-button_alt__inversed-text: $color-primary-background;
$color-button_alt__inversed__hover-background: $color-primary-background;
$color-button_alt__inversed__hover-border: $color-primary-background;
$color-button_alt__inversed__hover-text: $color-primary-background;
// ## Badges ##
$color-badge-1: $color-shade_1;
$color-badge-2: #bfb9a1;
//============================================

// Semantic colors mapping to components
// # COMMON #
$color-text__dark: $color-primary-background;
$color-text__light: $color-primary;
$color-overlay-background: $color-shade_5;

// # COMPONENTS #
$color-product_image-background: radial-gradient(circle, rgba($color-accent, 1) 0%, rgba($color-accent, 0) 50%);

// # PDP #
$color-product_gallery-thumb-border: rgba($color-shade_5, 0.35);
$color-product_gallery-thumb__selected-border: rgba($color-primary, 0.35);

// # PLP #
$color-filters-label: $color-input-border;

// ## MESSAGE ##
$color-message-text__inversed: $color-primary;

// ## Product tile ##
$color-product_tile-promo-background: $color-promo-background;
$color-product_tile-promo: $color-primary;

// ## Header ##
$color-header-background: $color-primary-background;
$color-header__transparent-text: $color-header_text;
$color-header__transparent__inverted-text: $color-header_alt;
$color-header__transparent-background-border: rgba($color-shade_4, 0.15);
$color-header__transparent-header_promo-background: $color-promo-background;
$color-navigation_flyout-background: $color-primary-background;
$color-hamburger_menu-background: $color-primary-background;
$color-hamburger_back_link-background: $color-primary;
$color-hamburger_account-background: $color-shade_6;
$color-cookies-background: $color-shade_5;//??
$color-header_promo__transparent_header-background: $color-badge-2;//+
$color-main_nav-divider: $color-shade_5;//??
$color-mobile_navigation-categories_divider: $color-shade_5;//+
$color-mobile_navigation-section_divider: $color-shade_4;

// ## Footer ##
$color-footer-background: $color-primary-background;
$color-footer-email_subscription-background: rgba($color-shade_6, 0.78);
$color-footer-border: $color-shade_11;

// ## NOTIFICATION ##
$color-notification-text: $color-primary-background;

// # ERROR PAGES #
$color-error_410-text: $color-text__light;

// # PAGE DESIGNER #
$color-banner_2_region: $color-primary;

// # PDP #
$color-product_gallery-thumbs_control-background: rgba($color-shade_6, 0.9);


// ## CHECKOUT ##
$color-checkout-card_icon: $color-shade_3;

$color-svg-icon: $color-shade_1;


// ## CAROUSEL ##
$color-carousel_pagination_control: $color-primary-background;
$color-carousel_pagination_control__active: $color-shade_1;
$color-carousel_control: $color-shade_1;
$color-carousel_control__disabled: #CDC8C8;

// ## PROGRESS BAR ##
$color-progress-bg: $color-primary;
$color-progress-value-bg:  $color-shade_5;
$progress-border-config: 1px solid $color-shade_5;

// ## Age gate
$color-age_gate-text: $color-shade_4 !default;

// # SWATCH
$color-swatch_border-selected: $color-shade_1;

// # Store locator
$color-store_locator-close_text: $color-shade_5;
$color-store_locator-close_background: $color-shade_8;
$color-store_locator-nav_text: $color-shade_9;


// ## CAROUSEL ##
$color-carousel_pagination_control: $color-primary-background;
$color-carousel_pagination_control__active: $color-shade_1;
$color-carousel_control: $color-shade_1;
$color-svg-icon: $color-shade_1;
$color-carousel_control__disabled: $color-shade_3;

// ## TEXT ##
$color-text-dimmed: $color-primary !default;//+
$color-form-label: $color-primary !default;//+
$color-form-input: $color-primary !default;
$color-form-caption: $color-primary !default;//+

// ## PRODUCT BADGE ##
$color-badge_1-background: $color-badge-1 !default;//+
$color-badge_1-color: $color-primary-background !default;//??

// #SVG icon color
$color-svg-icon: $color-shade_1;